<!--
 * @Author: your name
 * @Date: 2020-12-04 19:38:01
 * @LastEditTime: 2021-05-21 23:04:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\backsendsuccess\index.vue
-->
<template>
  <div class="allclass" v-wechat-title="$route.meta.title">
    <navigation
      :stylemsg="stylemsg"
      :noleft="true"
      msg="授权发送成功"
    ></navigation>
    <div class="themsgtop">
      <div class="imms">
        <img src="../../assets/users.png" alt="" />
        <div>授权发送成功</div>
      </div>
      <div class="msgdu">请提醒被调人查看短信链接和完成授权</div>
      <div class="buclas">
        <div @click="$router.push('/home')">返回首页</div>
        <div @click="toup">查看订单</div>
      </div>
    </div>
    <div class="fwsm">
      <div class="fwsm1">服务说明</div>
      <div class="fwsm2">
        <div>
          1.被调人超过24小时未通过授权视同拒绝授权，您将无权查看TA的报告；
        </div>
        <div>
          2.如选择委托方支付，候选人拒绝授权，订单金额会退还至您的账户余额，我们将收取6元基础系统服务费。
        </div>
      </div>
    </div>
    <!-- <div class="okclass">
      <img src="../../assets/selected.png" />
      <p class="thepclass">请提醒被调人查看短信链接<br>和完成授权</p>
    </div>
    <div class="msgclass">
      <div class="p1class">
        <div  class="p1c"></div>
        <div class="d1">被调人超过24小时未通过授权视同拒绝授权，您将无权查看TA的报告；</div>
      </div>
      <div class="p1class">
        <div class="p1c"></div>
        <div class="d1">
          如选择企业支付，候选人拒绝授权，订单金额会退还至您的账户余额。我们将收取6元基础系统服务费。
        </div>
      </div>
    </div> -->
    <!-- <button @click="$router.push('/home')" class="bton btn">朕知道了</button> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      stylemsg: "background-color: #F6F6F7;",
    };
  },
  methods:{
    toup(){
      this.$router.push({
        path:'/topupdetail',
        query:{
          num:2
        }
      })
    }
  }
};
</script>
<style scoped>
.fwsm {
  background-color: #ffffff;
  padding: 0.44rem;
  margin: 0.24rem;
  border-radius: 0.24rem;
}
.fwsm1 {
  font-size: 0.32rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.44rem;
  margin-bottom: 0.34rem;
}
.fwsm2 {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.4rem;
}
.fwsm2>:first-child{
  margin-bottom: .18rem;
}
.buclas {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 500;
  line-height: 0.48rem;
  justify-content: center;
}
.buclas > :first-child {
  color: #909399;
  padding: 0.18rem 0.98rem;
  border-radius: 0.42rem;
  border: 0.02rem solid #909399;
  margin-right: 0.2rem;
}
.buclas > :nth-child(2) {
  color: #ffffff;
  padding: 0.2rem 1rem;
  background: linear-gradient(90deg, #ff5f18 0%, #ff8d35 100%);
  border-radius: 0.42rem;
}
.msgdu {
  text-align: center;
  margin-top: 0.26rem;
  margin-bottom: 0.62rem;
  font-size: 0.28rem;
  font-weight: 500;
  color: #909399;
  line-height: 0.4rem;
}
.themsgtop {
  background-color: #ffffff;
  width: 100%;
  padding-top: 0.76rem;
  padding-bottom: 0.5rem;
}
.imms {
  display: flex;
  align-items: center;
  font-size: 0.4rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.44rem;
  justify-content: center;
}
.imms img {
  width: 0.5rem;
  margin-right: 0.2rem;
}
.allclass {
  background-color: #f6f6f7;
}
.thepclass {
  color: #f76729;
  line-height: 0.6rem;
}
.pclass {
  text-align: center;
  padding-top: 0.2rem;
  color: #141f33;
  font-size: 0.34rem;
  font-weight: 500;
}
.p1class {
  display: flex;
}
.p1c {
  min-width: 0.12rem;
  height: 0.12rem;
  border-radius: 50%;
  background-color: #fba110;
  margin-top: 0.1rem;
}

.okclass {
  font-weight: 500;
  font-size: 0.4rem;
  text-align: center;
  color: #222222;
  margin: 0.99rem 0 0.6rem 0;
}
.okclass img {
  width: 1.4rem;
  margin-bottom: 0.52rem;
}
.msgclass {
  margin: 0 1.14rem;
}
.msgclass .d1 {
  text-align: center;
  color: #7f7f7f;
  font-size: 0.24rem;
}
.msgclass div {
  margin-bottom: 0.35rem;
}
.btn {
  margin-top: 3.28rem;
  margin-bottom: 1rem;
}
</style>